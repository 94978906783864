<template>
  <a-drawer
    v-model:visible="visible"
    :maskClosable="false"
    width="1102px"
    :bodyStyle="{ padding: 0 }"
    :title="$t('idp.add_questionnaire_template')"
    @close="back"
  >
    <div class="select-content">
      <div class="search">
        <div class="item">
          <a-input
            allowClear
            :placeholder="`${$t('PleaseEnter')}${$t('CM_LB_Name')}`"
            v-model:value="params.questionnaireName"
            @change="searchChange"
          />
        </div>
        <div class="item">
          <a-tree-select
            allowClear
            show-search
            :dropdown-style="{
              maxHeight: '30vh',
              width: '300px',
              overflow: 'auto',
            }"
            :tree-data="folderData"
            :placeholder="`${$t('PleaseSelect')}${$t('LB_Course_Cate')}`"
            treeNodeFilterProp="title"
            :replaceFields="replaceFields"
            v-model:value="params.folderId"
            @change="searchChange"
          />
        </div>
        <div class="item">
          <a-select
            allowClear
            :placeholder="`${$t('PleaseSelect')}${$t('XB_Type')}`"
            v-model:value="params.questionnaireType"
            @change="searchChange"
          >
            <a-select-option :value="4">
              {{ $t("idp.survey_questionnaire") }}
            </a-select-option>
            <a-select-option :value="11">
              {{ $t("idp.vote") }}
            </a-select-option>
            <a-select-option :value="7">
              {{ $t("idp.evaluation_form") }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="check" v-if="listData.length">
        <div class="check-all">
          <a-checkbox
            v-model:checked="isCheckAll"
            :indeterminate="indeterminate"
            @change="checkAll"
          >
            {{ $t("idp.select_all_this_page") }}
          </a-checkbox>
        </div>
        <div class="checked">
          {{ $t("idp.selected") }}：<span>{{ checkList.length }}</span>
        </div>
      </div>
      <a-spin :spinning="loading">
        <template v-if="listData.length">
          <div class="list">
            <a-checkbox
              class="item"
              v-for="item in listData"
              :key="item.questionnaireId"
              v-model:checked="item.isCheck"
              @change="checkItem($event, item)"
            >
              <div class="cover">
                <img
                  v-if="item.questionnaireType == 4"
                  :src="require('@/assets/image/questionnaire-survey.png')"
                  alt="cover"
                />
                <img
                  v-else-if="item.questionnaireType == 11"
                  :src="require('@/assets/image/questionnaire-vote.png')"
                  alt="cover"
                />
                <img
                  v-else-if="item.questionnaireType == 7"
                  :src="require('@/assets/image/questionnaire-evaluation.png')"
                  alt="cover"
                />
              </div>
              <div class="text">
                <p class="h5">{{ item.questionnaireName }}</p>
                <p class="p">
                  <span>
                    {{ $t("idp.classification") }}：{{ item.folderName || "-" }}
                  </span>
                  <span>
                    {{ $t("idp.questionnaire_type") }}：<template
                      v-if="item.questionnaireType == 4"
                      >{{ $t("idp.survey_questionnaire") }}</template
                    ><template v-else-if="item.questionnaireType == 11">{{
                      $t("idp.vote")
                    }}</template
                    ><template v-else-if="item.questionnaireType == 7">{{
                      $t("idp.evaluation_form")
                    }}</template>
                  </span>
                </p>
              </div>
            </a-checkbox>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :defaultPageSize="12"
              v-model:current="params.page"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-else
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        />
      </a-spin>
      <div class="btns">
        <a-button type="primary" @click="save">
          {{ $t("idp.save") }}
        </a-button>
        <a-button @click="back">
          {{ $t("idp.return") }}
        </a-button>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";

import { questionnaireFolder, idpQuestionnaireList } from "@/api/idp";

import { debounce, cloneDeep } from "lodash";
const renderVNode = (_, { attrs: { vnode } }) => vnode;

export default defineComponent({
  components: {
    renderVNode,
  },
  setup(_, { emit }) {
    const state = reactive({
      visible: false,
      checkList: [],
      folderData: [],
      replaceFields: {
        children: "list",
        title: "name",
        key: "id",
        value: "id",
      },
      isCheckAll: false,
      indeterminate: false,
      params: {
        page: 1,
        pageSize: 12,
        sub: true,
        sortKey: "createTime",
        sort: 2,
        questionnaireName: "",
        folderId: null,
        questionnaireType: null,
      },
      loading: false,
      listData: [],
      pageTotal: 0,
    });

    const searchChange = debounce(() => {
      state.params.page = 1;
      getList();
    }, 500);

    questionnaireFolder().then((res) => {
      if (res.ret === 0) {
        state.folderData = res.data || [];
      }
    });

    const getList = () => {
      state.loading = true;
      idpQuestionnaireList(state.params).then((res) => {
        state.loading = false;
        if (res.ret === 0) {
          state.listData = res.data.list || [];
          state.pageTotal = res.data.totals;
          let checkNum = 0;
          state.listData.forEach((item) => {
            state.checkList.forEach((item2) => {
              if (item.questionnaireId == item2.questionnaireId) {
                if (item2.resourceId) item.resourceId = item2.resourceId;
                item.isCheck = true;
                checkNum++;
              }
            });
          });
          if (checkNum == state.listData.length) {
            state.isCheckAll = true;
            state.indeterminate = false;
          } else if (checkNum == 0) {
            state.isCheckAll = false;
            state.indeterminate = false;
          } else {
            state.isCheckAll = false;
            state.indeterminate = true;
          }
        }
      });
    };

    const pageChange = (n) => {
      state.params.page = n;
      getList();
    };

    const checkAll = (e) => {
      let val = e.target.checked;
      if (val) state.indeterminate = false;
      state.listData.forEach((item) => {
        if (val) {
          if (!item.isCheck) {
            state.checkList.push(item);
          }
        } else {
          let index = state.checkList
            .map((o) => o.questionnaireId)
            .indexOf(item.questionnaireId);
          state.checkList.splice(index, 1);
        }
        item.isCheck = val;
      });
    };

    const checkItem = (e, item) => {
      let val = e.target.checked;
      if (val) {
        state.checkList.push(item);
      } else {
        let index = state.checkList
          .map((o) => o.questionnaireId)
          .indexOf(item.questionnaireId);
        state.checkList.splice(index, 1);
      }
      let checkNum = 0;
      state.listData.forEach((item) => {
        if (item.isCheck) checkNum++;
      });
      if (checkNum == state.listData.length) {
        state.isCheckAll = true;
        state.indeterminate = false;
      } else if (checkNum == 0) {
        state.isCheckAll = false;
        state.indeterminate = false;
      } else {
        state.isCheckAll = false;
        state.indeterminate = true;
      }
    };

    const open = (data) => {
      state.listData = [];
      if (data) {
        state.checkList = cloneDeep(data);
      }
      state.params = {
        page: 1,
        pageSize: 12,
        sub: true,
        questionnaireName: "",
        folderId: null,
        questionnaireType: null,
      };
      getList();
      state.visible = true;
    };

    const back = () => {
      state.visible = false;
    };

    const save = () => {
      emit("save", cloneDeep(state.checkList));
      back();
    };

    return {
      ...toRefs(state),
      searchChange,
      pageChange,
      checkAll,
      checkItem,
      open,
      back,
      save,
    };
  },
});
</script>

<style lang="less" scoped>
.select-content {
  padding: 24px 16px 24px 24px;
  .search {
    .mixinFlex();
    flex-wrap: wrap;
    margin-bottom: 12px;
    .item {
      width: 300px;
      margin-right: 24px;
      margin-bottom: 12px;
      & > * {
        width: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .check {
    .mixinFlex(space-between; center);
    margin-bottom: 24px;
    span {
      color: @color-theme;
    }
  }
  .list {
    .mixinFlex();
    flex-wrap: wrap;
    .item {
      width: 252px;
      height: 224px;
      margin: 0 16px 16px 0 !important;
      border-radius: 6px;
      background-color: #fff;
      position: relative;
      box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.15);
      &:nth-child(4n) {
        margin-right: 0 !important;
      }
      ::v-deep(.ant-checkbox) {
        z-index: 1;
        position: absolute;
        top: 8px;
        left: 8px;
        & + span {
          padding: 0;
        }
      }
      .cover {
        .mixinImgWrap(252px; 142px);
        background-color: #fafafa;
        border-radius: 6px 6px 0 0;
      }
      .text {
        padding: 6px 8px;
        p {
          margin: 0;
          &.h5 {
            font-size: 14px;
            color: #333;
            .mixinEllipsis(44px, 2);
            margin-bottom: 6px;
          }
          &.p {
            .mixinFlex(space-between; center);
            span {
              width: 48%;
              font-size: 12px;
              color: #666;
              .mixinEllipsis(20px);
              &:last-child {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
  .btns {
    text-align: center;
    margin-top: 16px;
    padding-top: 24px;
    border-top: 1px solid #f4f4f4;
    .ant-btn {
      &:last-child {
        margin-left: 24px;
      }
    }
  }
}
</style>
